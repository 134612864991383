<template>
    <el-dialog :title="title?title:'代理商必须设置联系方式后才可继续使用本软件'" :destroy-on-close='true' :show-close='false'
        :close-on-click-modal="false" :visible.sync="isLock" width="35%" class="lockDialog" top="35vh">
        <!-- <p class="lockTitle">代理商必须设置联系方式后才可继续使用本软件</p> -->
        <div class="lockContent">
            <li><span>微信二维码：</span>
                <upload ref="uploadWx" :uploadImg="uploadImg" />
            </li>
            <li><span>微信号：</span> <el-input v-model="inputWx" placeholder="请输入微信号"></el-input></li>
        </div>
        <span slot="footer" class="dialogLock-footer">
            <el-button type="primary" @click="sureUpload">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
    import api from '../../http/api';
    import { mapState } from "vuex";
    import { Message } from 'element-ui';
    export default {
        data() {
            return {
                source: '',//来源
                title: '',
                inputWx: '',
                isLock: false,//是否锁住用户端
                uploadImg: '', //上传图片
            }
        },
        watch: {
            isLock: {
                handler(nval, oval) {
                    console.log('isLock--->', nval)
                    if (nval) {
                        console.log('uploadWx--->', this.uploadImg, this.currentAgentSet)
                        this.uploadImg = this.currentAgentSet.wechat_image
                        this.inputWx = this.currentAgentSet.wechat
                    }
                }
            }
        },
        computed: {
            ...mapState(['currentAgentSet']), //
        },
        components: {
            upload: () => import('../components/upload.vue'),
        },
        methods: {
            //上传联系方式
            sureUpload() {
                let that = this;
                console.log('sureUpload--->', that.inputWx, that.$refs.uploadWx.imageUrl)
                if (!that.$refs.uploadWx.imageUrl || !that.inputWx) {
                    Message({
                        message: !that.$refs.uploadWx.imageUrl ? '请上传微信二维码~' : '请输入微信号~',
                        type: 'error'
                    });
                    return;
                }
                api.setContact({
                    wechat: that.inputWx,
                    wechat_image: that.$refs.uploadWx.imageUrl
                }).then(res => {
                    // console.log('setContact--->',res)
                    if (res.code == 200) {
                        that.isLock = false;
                        Message({
                            message: '设置成功~',
                            type: 'success'
                        });
                        that.uploadImg = ''
                        that.$emit('reload', true)
                    } else {
                        Message({
                            message: res.msg,
                            type: 'error'
                        }); 
                    }
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .lockDialog .el-dialog__body {
        padding: 0px 20px 10px 20px;
    }

    /deep/ .lockDialog .el-button {
        padding: 8px 20px;
    }

    /deep/ .lockDialog .el-dialog__footer {
        text-align: center
    }

    .lockContent {
        margin-top: 20px;
    }

    .lockContent li {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;

        span {
            width: 90px;
        }
    }
</style>